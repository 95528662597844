import _toConsumableArray from "/tmp/build/example/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty2 from "/tmp/build/example/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/tmp/build/example/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import * as colors from '@material-ui/core/colors';
import createPalette from '@material-ui/core/styles/createPalette';
import darkBlue from './colors/darkBlue';
import cerise from './colors/cerise';
import moneyGreen from './colors/moneyGreen';
import amber from './colors/amber';
import cyan from './colors/cyan';
import green from './colors/green';
import lightBlue from './colors/lightBlue';
import lightGreen from './colors/lightGreen';
import lime from './colors/lime';
import orange from './colors/orange';
import yellow from './colors/yellow';

var common = colors.common,
    actualColors = _objectWithoutProperties(colors, ["common"]);

var toPalette = function toPalette(_ref) {
  var _ref2 = _slicedToArray(_ref, 2),
      name = _ref2[0],
      color = _ref2[1];

  return _defineProperty2({}, name, createPalette({
    primary: color
  }));
};

var defaultColors = Object.assign.apply(Object, _toConsumableArray(Object.entries(actualColors).map(toPalette)));
export default _objectSpread({}, defaultColors, {
  cerise: createPalette(cerise),
  darkBlue: createPalette(darkBlue),
  moneyGreen: createPalette(moneyGreen),
  cyan: createPalette(cyan),
  amber: createPalette(amber),
  green: createPalette(green),
  lightBlue: createPalette(lightBlue),
  lightGreen: createPalette(lightGreen),
  lime: createPalette(lime),
  orange: createPalette(orange),
  yellow: createPalette(yellow)
});